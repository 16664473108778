import React from "react";
import { convertToCandle } from "./convertToCandle";
export function useCandles(symbol, interval, limit) {
  const [candles, setCandles] = React.useState([]);
  React.useEffect(() => {
    const work = () => {
      fetch(
        "https://api.binance.com/api/v3/klines?symbol=" +
          symbol +
          "&interval=" +
          interval +
          "&limit=" +
          limit
      )
        .then((response) => response.json())
        .then((data) => {
          const arr = data.map(function (d) {
            return convertToCandle(d);
          });
          setCandles(arr);
        });
    };
    work();

    const i = setInterval(work, 20*1000);
    return () => {
      clearInterval(i);
    }
  }, [symbol, interval, limit]);

  return candles;
}
