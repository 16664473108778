export function sortFunction(array, sortBy) {
  if (sortBy === "Date") {
    return array.sort(function (obj1, obj2) {
      if (obj1.date === obj2.date) {
        return 0;
      }
      if (obj1.date < obj2.date) {
        return 1;
      }
      return -1;
    });
  }
  if (sortBy === "Dip") {
    return array.sort(function (obj1, obj2) {
      return obj1.diffRaw - obj2.diffRaw;
    });
  }
  if (sortBy === "Pump") {
    return array.sort(function (obj1, obj2) {
      return obj2.diffRaw - obj1.diffRaw;
    });
  }
}
