import { ICandle } from "./ICandle";

/* SYNTAX OF RAW DATA
[
  [
    1499040000000,      // Open time
    "0.01634790",       // Open
    "0.80000000",       // High
    "0.01575800",       // Low
    "0.01577100",       // Close
    "148976.11427815",  // Volume
    1499644799999,      // Close time
    "2434.19055334",    // Quote asset volume
    308,                // Number of trades
    "1756.87402397",    // Taker buy base asset volume
    "28.46694368",      // Taker buy quote asset volume
    "17928899.62484339" // Ignore.
  ]
]
*/
function numParse(num) {
  return num > 100 ? Math.round(num) : parseFloat(num);
}

export function getPercent(open, close) {
  const o = parseFloat(open);
  const c = parseFloat(close);
  if (c > o) {
    const diff = c - o;
    const result = diff / o;
    return (100 * result); 
  }

  const diff = o - c;
  const result = diff / o;
  return (100 * result) * -1; 
} 
export function convertToCandle(d: any): ICandle {
  const date = new Date(d[0]).toISOString().substring(0, 16).replace("T", " ");
  const open = parseFloat(d[1].toString());
  const high = numParse(d[2]);
  const low = numParse(d[3]);
  const close = numParse(d[4]);
  const diff = getPercent(open, close).toFixed(2);
  const diffRaw = getPercent(open, close);

  const interDayDiff = getPercent(high, low).toFixed(2);
  const result = {
    date,
    open,
    high,
    low,
    close,
    diff,
    diffRaw,
    interDayDiff,
  };

  return result;
}
