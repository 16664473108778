import React from "react";

export function useTradingPairs() {
    const [tradingPairs, setTradingPairs] = React.useState([]);

    React.useEffect(() => {
        fetch("https://api.binance.com/api/v1/exchangeInfo")
            .then((response) => response.json())
            .then((data) => {
                const symbols = data.symbols
                    .map(function (s) {
                        return s.symbol;
                    })
                    .filter(function (f) {
                        if(f.isMarginTradingAllowed === false){
                            return null;
                        }
                        if(f.isSpotTradingAllowed === false){
                            return null;
                        }
                        if (f.indexOf("BTC") > 0) {
                            return f;
                        }
                        if (f.indexOf("USDT") > 0) {
                            return f;
                        }
                        return null;
                    }).sort();
                symbols.push("");
                setTradingPairs(symbols);
            });
    }, []);
    return tradingPairs;
}
