import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import React from "react";
import ReactDOM from "react-dom";
import { sortFunction } from "./sortFunction";
import { useCandles } from "./useCandles";
import { useTradingPairs } from "./useTradingPairs";
import Autocomplete from "@material-ui/lab/Autocomplete";
function numberToSats(num) {
  return Math.round(num * (100 * 1000000));
}
function App() {
  const [symbol, setSymbol] = React.useState("BTCUSDT");
  const [limit, setLimit] = React.useState("10");

  const [_interval, _setInterval] = React.useState("1d");
  const candlesRaw = useCandles(symbol, _interval, limit);
  const tradingPairs = useTradingPairs();
  const [sortBy, setSortBy] = React.useState("Date");

  const candles = sortFunction(candlesRaw.slice(), sortBy);

  const isBTC = symbol.endsWith("BTC");
  return (
    <Paper elevation={5} style={{ padding: "20px" }}>
      <Typography variant="h4" component="h1">
        Crypto prices
      </Typography>
      <div
        style={{
          marginTop: "22px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <div>
          <div>
            <FormControl>
              <Autocomplete
                id="combo-box-demo"
                options={tradingPairs}
                style={{ width: 300 }}
                getOptionLabel={(option) => {
                  return option;
                }}
                getOptionSelected={(option, value) => {
                  //nothing that is put in here will cause the warning to go away
                  if (value === "") {
                    return true;
                  } else if (value === option) {
                    return true;
                  }
                }}
                onChange={(_, data) => {
                  if (!data) {
                    return;
                  }
                  const value = "" + data;
                  setSymbol(value);
                }}
                value={symbol}
                renderInput={(params) => (
                  <TextField {...params} label="Combo box" variant="outlined" />
                )}
              />
            </FormControl>
          </div>
        </div>

        <FormControl>
          <InputLabel id="sortby-label">Sort by</InputLabel>
          <Select
            labelId="sortby-label"
            name="sortBy"
            onChange={(event) => {
              const value = "" + event.target.value;
              setSortBy(value);
            }}
            value={sortBy}
          >
            <MenuItem value="Date">Date</MenuItem>
            <MenuItem value="Dip">Dip</MenuItem>
            <MenuItem value="Pump">Pump</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel id="interval-label">Interval</InputLabel>
          <Select
            labelId="interval-label"
            id="interval"
            value={_interval}
            onChange={(event) => _setInterval("" + event.target.value)}
          >
            <MenuItem value="1M">1M</MenuItem>
            <MenuItem value="1w">1w</MenuItem>
            <MenuItem value="3d">3d</MenuItem>
            <MenuItem value="1d">1d</MenuItem>
            <MenuItem value="12h">12h</MenuItem>
            <MenuItem value="6h">6h</MenuItem>
            <MenuItem value="4h">4h</MenuItem>
            <MenuItem value="1h">1h</MenuItem>
            <MenuItem value="15m">15m</MenuItem>
            <MenuItem value="5m">5m</MenuItem>
            <MenuItem value="3m">3m</MenuItem>
            <MenuItem value="1m">1m</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel id="limit-label">Limit</InputLabel>
          <Select
            labelId="limit-label"
            id="limit"
            name="limit"
            value={limit}
            onChange={(event) => setLimit("" + event.target.value)}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
          </Select>
        </FormControl>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Open</TableCell>
              <TableCell>Close</TableCell>
              <TableCell>High</TableCell>
              <TableCell>Low</TableCell>
              <TableCell>% Diff</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {candles.map(function (candle) {
              return (
                <TableRow key={candle.date}>
                  <TableCell className="align-left">{candle.date}</TableCell>
                  <TableCell>
                    {isBTC ? numberToSats(candle.open) : candle.open}
                  </TableCell>
                  <TableCell>
                    {isBTC ? numberToSats(candle.close) : candle.close}
                  </TableCell>
                  <TableCell>
                    {isBTC ? numberToSats(candle.high) : candle.high}
                  </TableCell>
                  <TableCell>
                    {isBTC ? numberToSats(candle.low) : candle.low}
                  </TableCell>
                  <TableCell className="aligh-right">{candle.diff}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

ReactDOM.render(<App />, document.getElementById("app"));
